import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Button, Description, Row, Stack, Text } from '@ssnc/ui-core';

import { useAccountAdminStatusApi } from 'pages/Account/hooks';
import { type AccountDetailsParams } from 'types';

import { useAccountAdminStatus } from './hooks';
import { StatusFieldPlaceholder } from './components';
import { type AccountAdminStatusFieldProps } from './types';

export function AccountAdminStatusField({ disabled }: AccountAdminStatusFieldProps) {
  const { accountId: id = '' } = useParams<AccountDetailsParams>();
  const { formatMessage } = useIntl();

  const { tenantAdminDetails } = useAccountAdminStatusApi(id);

  const { handleStatusAction, userStatus, buttonLabel, showStatusButton, isLoading } =
    useAccountAdminStatus(tenantAdminDetails);

  const showStatus = userStatus && !isLoading;

  return (
    <Stack gap={300}>
      <Text type="h5">{formatMessage({ id: 'account.details.status.label' })}</Text>
      <Description>
        <Row gap={400} align="center">
          {showStatus ? (
            <>
              <Text>{userStatus}</Text>
              {showStatusButton && (
                <Button importance="primary" disabled={isLoading || disabled} onClick={handleStatusAction}>
                  {buttonLabel}
                </Button>
              )}
            </>
          ) : (
            <StatusFieldPlaceholder />
          )}
        </Row>
      </Description>
    </Stack>
  );
}
