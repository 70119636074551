import { type StatusLabelVariant } from '@ssnc/ui-core/components/StatusLabel/types';

import { TenantStatus } from 'types/entities/Tenant';

export function statusVariantFormatter(status: string | undefined): StatusLabelVariant {
  switch (status) {
    case TenantStatus.ACTIVE:
      return 'success';
    case TenantStatus.PERMANENTLY_DELETED:
      return 'danger';
    case TenantStatus.PENDING:
      return 'information';
    default:
      return 'none';
  }
}
