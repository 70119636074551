import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Row, Text } from '@ssnc/ui-core';
import { FormContent, FormSection } from '@ssnc/ui-pattern-forms';

import { FormLayout, Form } from '@blueprism/nextgen-platform-components';
import { ApiErrorBoundary } from '@blueprism/nextgen-api';

import { type AccountDetailsParams } from 'types';
import { TenantStatus } from 'types/entities/Tenant';

import { useTenantDetailsApi } from '../hooks';

import { DeleteAccountFormSection } from './components';

export function AccountStatus() {
  const { accountId: id = '' } = useParams<AccountDetailsParams>();
  const { formatMessage } = useIntl();
  const { tenant } = useTenantDetailsApi(id);

  const { status } = tenant;

  const isLoading = !status;
  const isActive = status !== TenantStatus.PERMANENTLY_DELETED;

  return (
    <Row width="100%" justify="between">
      <Form loading={isLoading}>
        <FormLayout>
          <FormContent>
            <FormSection title={formatMessage({ id: 'account.status.accountStatus.title' })}>
              <Text>{formatMessage({ id: `account.data.status.${tenant.status}` })}</Text>
            </FormSection>
            {isActive && (
              <ApiErrorBoundary
                marginTop="10vh"
                forbiddenErrorMessage={formatMessage({ id: 'account.status.deleteTenant.forbidden' })}
              >
                <DeleteAccountFormSection />
              </ApiErrorBoundary>
            )}
          </FormContent>
        </FormLayout>
      </Form>
    </Row>
  );
}
