import { type GetProductSkusRouteFunction, type GetRegionRouteFunction } from './types';

export namespace AppRoutes {
  export const Index = '/';
  export const Account = '/account';

  export const AccountRegion = `${Account}/details/:regionName/:accountId`;
  export const AccountDetails = `${AccountRegion}/:mode?`;
  export const Onboarding = `${AccountRegion}/onboarding`;
  export const Products = `${AccountRegion}/products`;
  export const AccountStatus = `${AccountRegion}/status`;
  export const getAccountDetailsUrl: GetRegionRouteFunction = (regionName = ':regionName', accountId = ':accountId') =>
    `${Account}/details/${regionName.toLowerCase()}/${accountId}`;
  export const getEditAccountDetailsUrl: GetRegionRouteFunction = (regionName, accountId) =>
    `${getAccountDetailsUrl(regionName, accountId)}/edit`;
  export const getProductsURL: GetRegionRouteFunction = (regionName = ':regionName', accountId = ':accountId') =>
    `${getAccountDetailsUrl(regionName, accountId)}/products`;

  export const AccountAdd = `${Account}/add`;

  const AccountProductConfiguration = `${Account}/product-configuration`;
  export const ProductConfiguration = `${AccountProductConfiguration}/:regionName/:accountId/:productId`;
  export const getProductConfigurationUrl: GetProductSkusRouteFunction = (
    regionName = ':regionName',
    accountId = ':accountId',
    productId = ':productId',
  ) => `${AccountProductConfiguration}/${regionName.toLowerCase()}/${accountId}/${productId}`;

  export const signOut = '/sign-out';
}
