import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { ModalDialog } from '@ssnc/ui-pattern-modal';
import { Stack, Strong, Text } from '@ssnc/ui-core';
import { CheckboxFormField } from '@ssnc/ui-pattern-forms';

import { type AccountStatusInput, type DeleteAccountModalProps } from 'pages/Account/AccountStatus/types';

export function DeleteAccountModal({ open, onCancel, onConfirm }: DeleteAccountModalProps) {
  const { formatMessage } = useIntl();
  const formikHelpers = useFormikContext<AccountStatusInput>();

  const { values, resetForm, setErrors } = formikHelpers;

  function handleClose() {
    resetForm();

    onCancel();
  }

  function handleConfirm() {
    if (!values.confirm) {
      // Checkbox when they are the first item in a form does not validate correctly with the yup.
      // Either we have the error triggered on mount or it does not trigger the error at all,
      // that is why we are setting the errors manually with formik helpers
      setErrors({
        confirm: formatMessage({ id: 'account.status.deleteTenant.confirm.error.required.message' }),
      });

      return;
    }

    handleClose();

    onConfirm(formikHelpers);
  }

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      modalId="confirm-delete-tenant-modal"
      modalHeaderTitle={formatMessage({ id: 'account.status.deleteTenant.modal.header' })}
      primaryActionText={formatMessage({ id: 'account.status.deleteTenant.modal.primaryAction' })}
      onPrimaryActionClick={handleConfirm}
      secondaryActionText={formatMessage({ id: 'account.status.deleteTenant.modal.secondaryAction' })}
      onSecondaryActionClick={handleClose}
      overlayVisible
      type="warning"
    >
      <Stack gap={200}>
        <Text>{formatMessage({ id: 'account.status.deleteTenant.modal.text' })}</Text>
        <Stack gap={100}>
          <Text>
            <Strong>{formatMessage({ id: 'account.status.deleteTenant.modal.confirm.title' })}</Strong>
          </Text>
          <CheckboxFormField
            label={formatMessage({ id: 'account.status.deleteTenant.modal.checkbox.label' })}
            name="confirm"
          />
          <Text type="caption" themeColor="disabled">
            {formatMessage({ id: 'account.status.deleteTenant.modal.confirm.text' })}
          </Text>
        </Stack>
      </Stack>
    </ModalDialog>
  );
}
