export enum Error {
  DomainNameUnavailable = 'DomainNameUnavailable',
  TenantNameValidation = 'TenantNameFormat',
}

export interface ValidationFailures {
  propertyName: string;
  errorMessage: string;
  errorCode: string;
  messagePlaceholderValues?: {
    PropertyName: string;
    PropertyValue: unknown;
  };
}
