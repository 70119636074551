import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Box, Button, Cluster } from '@ssnc/ui-core';
import { CardHeader, Card } from '@ssnc/ui-pattern-card';

import { type AccountDetailsParams } from 'types';
import { useTenantDetailsApi } from 'pages/Account/hooks/';
import { TenantStatus } from 'types/entities/Tenant';

import { type ProductCardProps } from './types';

export function ProductCard({ label, buttonLabel, onClick }: ProductCardProps) {
  const { accountId: id = '' } = useParams<AccountDetailsParams>();

  const { tenant } = useTenantDetailsApi(id);
  const { formatMessage } = useIntl();

  function handleClick() {
    onClick && onClick();
  }

  return (
    <Box width="30rem">
      <Card>
        <CardHeader title={label} />
        <Cluster justify="end" padding={200}>
          {tenant.status !== TenantStatus.PERMANENTLY_DELETED && (
            <Button importance="primary" onClick={handleClick}>
              {formatMessage({ id: buttonLabel })}
            </Button>
          )}
        </Cluster>
      </Card>
    </Box>
  );
}
