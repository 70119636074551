import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'routes';
import { useRegionContext } from 'services/region';

export function useAccountMultiRegionRouting(currentRegion?: string) {
  const { selectedManagementRegion } = useRegionContext();
  const navigate = useNavigate();

  const accountRegion = currentRegion || selectedManagementRegion?.name;

  function goToAccountDetails(accountId?: string) {
    navigate(AppRoutes.getAccountDetailsUrl(accountRegion, accountId));
  }

  function goToEditAccountDetails(accountId?: string) {
    navigate(AppRoutes.getEditAccountDetailsUrl(accountRegion, accountId));
  }

  function goToProductSkusListPage(accountId: string, productId: string) {
    navigate(AppRoutes.getProductConfigurationUrl(accountRegion, accountId, productId));
  }

  return {
    goToAccountDetails,
    goToEditAccountDetails,
    goToProductSkusListPage,
  };
}
