import { useState } from 'react';

import { type FormikProps } from 'formik';
import { useIntl } from 'react-intl';

import { Button, Row } from '@ssnc/ui-core';
import { FormSection } from '@ssnc/ui-pattern-forms';
import { InlineNotification } from '@ssnc/ui-pattern-inline-notification';

import { useAccountDeleteApi } from 'pages/Account/AccountStatus/hooks';
import { type AccountStatusInput } from 'pages/Account/AccountStatus/types';

import { DeleteAccountModal } from '../DeleteAccountModal';

export function DeleteAccountFormSection() {
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const { handleSubmit } = useAccountDeleteApi();
  const { formatMessage } = useIntl();

  function handleOpen() {
    setOpenConfirmDeleteModal(true);
  }

  function handleClose() {
    setOpenConfirmDeleteModal(false);
  }

  function handleConfirm(formikHelpers: FormikProps<AccountStatusInput>) {
    handleClose();

    handleSubmit(formikHelpers);
  }

  return (
    <FormSection title={formatMessage({ id: 'account.status.deleteTenant.title' })}>
      <InlineNotification
        isVisible
        variant="danger"
        notificationMessage={formatMessage({ id: 'account.status.deleteTenant.danger' })}
      />
      <Row>
        <Button variant="danger" onClick={handleOpen}>
          {formatMessage({ id: 'account.status.deleteTenant.button' })}
        </Button>
      </Row>
      <DeleteAccountModal open={openConfirmDeleteModal} onCancel={handleClose} onConfirm={handleConfirm} />
    </FormSection>
  );
}
