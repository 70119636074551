import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { type FormikProps } from 'formik';
import { useIntl } from 'react-intl';

import { ToastType, useToast } from '@ssnc/ui-pattern-toasts';

import { throwOnError, useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';
import { type AccountStatusInput } from 'pages/Account/AccountStatus/types';
import { AppRoutes } from 'routes/';

import { DELETE_TENANT, type DeleteTenantVariables } from './api';

export function useAccountDeleteApi() {
  const { accountId: id = '', regionName } = useParams<AccountDetailsParams>();

  const queryClient = useQueryClient();
  const { requestWithPrefix } = useApiClient();
  const { triggerToast } = useToast();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { mutateAsync } = useMutation({
    throwOnError: throwOnError,
    mutationFn: (variables: DeleteTenantVariables) =>
      requestWithPrefix<DeleteTenantVariables>(getManagementRegionPrefix(regionName), DELETE_TENANT, variables),
  });

  async function handleSubmit(formikHelpers: FormikProps<AccountStatusInput>) {
    formikHelpers.setSubmitting(true);

    await mutateAsync({
      input: {
        id,
      },
    });

    triggerToast({
      type: ToastType.SUCCESS,
      title: formatMessage({ id: 'account.status.deleteTenant.success.title' }),
      description: formatMessage({ id: 'account.status.deleteTenant.success.message' }),
    });

    await queryClient.invalidateQueries({
      queryKey: [QueryKeys.GetTenantList, QueryKeys.GetTenantDetails, id, regionName],
    });

    formikHelpers.resetForm();

    formikHelpers.setSubmitting(false);

    navigate(AppRoutes.Account);
  }

  return { handleSubmit };
}
