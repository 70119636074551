import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { PageLayout } from '@ssnc/ui-layouts';
import { Breadcrumbs, Link, Placeholder, Text } from '@ssnc/ui-core';

import { ApiErrorBoundary } from '@blueprism/nextgen-api';
import { useBreadcrumbs } from '@blueprism/nextgen-shared-hooks';

import { AppRoutes } from 'routes';
import { useTenantNameApi } from 'pages/Account/hooks';
import { ProductActivationFormDrawer, StyledTitleText } from 'components';

import { useActivatedProductApi } from './hooks';
import { ProductConfigurationView } from './ProductConfigurationView';
import { type ProductConfigurationParams } from './types';

export function ProductConfigurationPage() {
  const { formatMessage } = useIntl();
  const { accountId = '', productId = '', regionName = '' } = useParams<ProductConfigurationParams>();
  const { tenantName = '', isLoading } = useTenantNameApi(accountId);

  const { productName, isProductLoading } = useActivatedProductApi(accountId, productId);

  const breadCrumbLinks = [
    { href: AppRoutes.Account, title: formatMessage({ id: 'navigation.account.label' }) },
    {
      href: AppRoutes.getAccountDetailsUrl(regionName, accountId),
      title: tenantName,
      dependOnLoading: true,
    },
    { title: productName, dependOnLoading: true },
  ];
  const { breadcrumbs } = useBreadcrumbs(breadCrumbLinks, isLoading || isProductLoading);

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs ariaLabel={formatMessage({ id: 'navigation.breadcrumbs.productConfiguration.ariaLabel' })}>
          {breadcrumbs.map(({ title, navigable, ...linkProps }) =>
            navigable ? (
              <Link {...linkProps} key={title}>
                {title}
              </Link>
            ) : (
              <Text key={title}>{title}</Text>
            ),
          )}
        </Breadcrumbs>
      }
      pageTitle={
        isProductLoading ? (
          <Placeholder width="16rem" height={400} />
        ) : (
          <StyledTitleText type="h2">{productName}</StyledTitleText>
        )
      }
      pageContent={
        <ApiErrorBoundary marginTop="10vh">
          <ProductActivationFormDrawer>
            <ProductConfigurationView name={productName} />
          </ProductActivationFormDrawer>
        </ApiErrorBoundary>
      }
    />
  );
}
