import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';
import { ClientError } from 'graphql-request';

import { ToastType, useToast } from '@ssnc/ui-pattern-toasts';

import { type ValidationFailures, ApiStatusCode, checkServerErrorStatus, useApiClient } from '@blueprism/nextgen-api';
import { isEmpty } from '@blueprism/nextgen-utils';

import { AppRoutes } from 'routes';
import { getManagementRegionPrefix, useRegionContext } from 'services/region';
import { Error } from 'types/Errors';

import { type AccountCreationFormData } from '../../types';

import { type AddTenantVariables } from './api/types';
import { type AddTenantResponse, CREATE_TENANT } from './api';
import { type AccountSubmitData, type UseAccountCreationPageApiProps } from './types';

export function useAccountCreationPageApi({
  isDomainVerified,
  setIsDomainNotVerified,
}: UseAccountCreationPageApiProps) {
  const { requestWithPrefix } = useApiClient();
  const { getManagementRegion } = useRegionContext();

  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { triggerToast } = useToast();

  function handleRedirectToProducts(accountId: string | undefined, selectedRegion: string) {
    navigate(AppRoutes.getProductsURL(selectedRegion, accountId));
  }

  function handleRedirectToGrid() {
    navigate(AppRoutes.Account);
  }

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ managementRegion: { name }, ...variables }: AccountSubmitData) => {
      const managementRegionPrefix = getManagementRegionPrefix(name);

      return requestWithPrefix<AddTenantResponse, AddTenantVariables>(managementRegionPrefix, CREATE_TENANT, {
        ...variables,
        managementLocation: name,
      });
    },
    meta: {
      skipBadRequest: true,
    },
  });

  async function handleSubmit({
    adminFirstName = '',
    adminLastName = '',
    email = '',
    name = '',
    domainName = '',
    managementRegion = { id: '', value: '' },
  }: Partial<AccountCreationFormData>) {
    if (!isDomainVerified) {
      setIsDomainNotVerified(true);

      return;
    }

    const region = getManagementRegion(managementRegion.id);

    if (isEmpty(region)) {
      return;
    }

    try {
      const response = await mutateAsync({
        adminFirstName,
        adminLastName,
        email,
        name,
        domainName,
        managementRegion: region,
      });

      const accountData = response.createTenant;

      triggerToast({
        type: ToastType.SUCCESS,
        title: formatMessage({ id: 'account.notification.accountCreated' }),
        description: formatMessage(
          { id: 'account.notification.accountCreated.message' },
          { accountName: name, managementRegion: managementRegion.value },
        ),
      });

      handleRedirectToProducts(accountData?.entity?.id, region?.name);
    } catch (error) {
      if (error instanceof ClientError && checkServerErrorStatus(error, ApiStatusCode.BadRequest)) {
        error?.response?.errors?.forEach(({ extensions: { problemDetails } }) => {
          const validationFailures = problemDetails?.validationFailures as ValidationFailures[];

          const errorCode = validationFailures?.[0].errorCode;

          switch (errorCode) {
            case Error.DomainNameUnavailable: {
              triggerToast({
                type: ToastType.ALERT,
                title: formatMessage({ id: 'account.notification.accountCreationFailed' }),
                description: formatMessage(
                  { id: 'account.notification.accountCreationFailed.message' },
                  { accountName: name },
                ),
              });

              break;
            }
            default: {
              triggerToast({
                type: ToastType.ALERT,
                title: formatMessage({ id: 'error.badRequest.toast.title' }),
                description: formatMessage({ id: 'error.badRequest.toast.description' }),
              });
            }
          }
        });
      }
    }
  }

  return { isPending, handleSubmit, handleRedirectToGrid };
}
