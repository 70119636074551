import * as Yup from 'yup';

import { type CreateValidation } from '@blueprism/nextgen-platform-components';

import { type LanguageKeys } from 'types/language';

import { type ActivateProductFormData } from '../../types';

export const createValidation: CreateValidation<Partial<ActivateProductFormData>> = (formatMessage) => {
  const t = (text: LanguageKeys) => formatMessage({ id: text });

  return {
    skuSchema: Yup.object().test({
      test: (value: Yup.AnyObject) => value?.id,
      message: t('account.products.activateProducts.drawer.sku.error'),
    }),
  };
};
