import * as Yup from 'yup';
import { type PrimitiveType } from 'intl-messageformat';

import { yupSequence } from '@ssnc/ui-pattern-forms';

import {
  EMAIL_VALIDATION,
  UNICODE_NUMBERS_SPECIAL_CHARACTERS_VALIDATION,
  UNICODE_NUMBERS_SPECIAL_CHARACTERS_WITHOUT_UNDERSCORE_VALIDATION,
  type CreateTemplateType,
} from '@blueprism/nextgen-platform-components';
import { type FormatMessage } from '@blueprism/nextgen-utils';

import { type LanguageKeys } from 'types/language';

import {
  TENANT_NAME_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  DOMAIN_NAME_SUBDOMAIN_MAX_LENGTH,
  DOMAIN_NAME_DISALLOWED_CHARACTERS_TO_VALIDATE,
} from './constants';
import { getDomainNameMaxLength } from './utils';
import { type AccountCreationFormData } from './types';

export function createValidation(
  formatMessage: FormatMessage,
  maxTenantSubdomainLevel: number,
): CreateTemplateType<AccountCreationFormData> {
  function t(text: LanguageKeys, values?: Record<string, PrimitiveType>) {
    return formatMessage({ id: text }, values);
  }

  const domainNameMaxLength = getDomainNameMaxLength(maxTenantSubdomainLevel);

  return {
    adminFirstNameSchema: yupSequence('adminFirstName', Yup.string(), (type) => [
      type
        .max(FIRST_NAME_MAX_LENGTH, t('account.create.firstName.error.max.length'))
        .required(t('account.create.firstName.error')),
      type.matches(UNICODE_NUMBERS_SPECIAL_CHARACTERS_WITHOUT_UNDERSCORE_VALIDATION, {
        name: 'disallowed-characters-validation',
        message: ({ value }: { value: string }) => {
          if (!value) return undefined;

          return t('account.create.firstName.error.invalid.characters');
        },
      }),
      type
        .test({
          test: (value) => !value?.startsWith(' '),
          name: 'restrict-start-with-space-validation',
          message: t('account.create.firstName.error.starts.with.space'),
        })
        .test({
          test: (value) => !value?.startsWith('-'),
          name: 'restrict-start-with-hyphen-validation',
          message: t('account.create.firstName.error.starts.with.hyphen'),
        }),
    ]),
    adminLastNameSchema: yupSequence('adminLastName', Yup.string(), (type) => [
      type
        .max(LAST_NAME_MAX_LENGTH, t('account.create.lastName.error.max.length'))
        .required(t('account.create.lastName.error')),
      type.matches(UNICODE_NUMBERS_SPECIAL_CHARACTERS_WITHOUT_UNDERSCORE_VALIDATION, {
        name: 'disallowed-characters-validation',
        message: ({ value }: { value: string }) => {
          if (!value) return undefined;

          return t('account.create.lastName.error.invalid.characters');
        },
      }),
      type
        .test({
          test: (value) => !value?.startsWith(' '),
          name: 'restrict-start-with-space-validation',
          message: t('account.create.lastName.error.starts.with.space'),
        })
        .test({
          test: (value) => !value?.startsWith('-'),
          name: 'restrict-start-with-hyphen-validation',
          message: t('account.create.lastName.error.starts.with.hyphen'),
        }),
    ]),
    emailSchema: Yup.string()
      .max(EMAIL_MAX_LENGTH, t('account.create.email.error'))
      .required(t('account.create.email.error'))
      .matches(EMAIL_VALIDATION, t('account.create.email.error')),
    nameSchema: yupSequence('name', Yup.string(), (type) => [
      type
        .required(t('account.create.tenantName.error'))
        .trim(t('account.create.tenantName.error'))
        .max(TENANT_NAME_MAX_LENGTH, t('account.create.tenantName.error.max.length')),
      type.matches(UNICODE_NUMBERS_SPECIAL_CHARACTERS_VALIDATION, {
        name: 'disallowed-characters-validation',
        message: ({ value }: { value: string }) => {
          if (!value) return undefined;

          return t('account.create.tenantName.error.invalid.characters');
        },
      }),
      type
        .test({
          test: (value) => !value?.startsWith(' '),
          name: 'restrict-start-with-space-validation',
          message: t('account.create.tenantName.error.starts.with.space'),
        })
        .test({
          test: (value) => !value?.startsWith('_'),
          name: 'restrict-start-with-underscore-validation',
          message: t('account.create.tenantName.error.starts.with.underscore'),
        })
        .test({
          test: (value) => !value?.startsWith('-'),
          name: 'restrict-start-with-hyphen-validation',
          message: t('account.create.tenantName.error.starts.with.hyphen'),
        }),
    ]),
    domainNameSchema: yupSequence(
      'domainName',
      Yup.string(),
      (type) => [
        type
          .required(t('account.create.domainName.error'))
          .trim(t('account.create.domainName.error'))
          .max(domainNameMaxLength, t('account.create.domainName.error.max.length', { max: domainNameMaxLength })),
        type.matches(DOMAIN_NAME_DISALLOWED_CHARACTERS_TO_VALIDATE, {
          name: 'disallowed-characters-validation',
          message: ({ value }: { value: string }) => {
            if (!value) return undefined;

            return t('account.create.domainName.error.invalid.characters');
          },
        }),
        type
          .test({
            test: (value: string = '') => {
              const subdomains = value.split('.');

              return subdomains.length <= maxTenantSubdomainLevel;
            },
            name: 'restrict-max-subdomains-validation',
            message: t('account.create.domainName.error.max.subdomains', { max: maxTenantSubdomainLevel }),
          })
          .test({
            test: (value: string = '') => {
              if (!value.length) {
                return true;
              }
              const subdomains = value.split('.');

              return subdomains.every((subdomain) => subdomain.length > 0);
            },
            name: 'restrict-subdomain-min-length-validation',
            message: t('account.create.domainName.error.min.subdomain.length'),
          })
          .test({
            test: (value: string = '') => {
              const subdomains = value.split('.');

              return subdomains.every((subdomain) => subdomain.length <= DOMAIN_NAME_SUBDOMAIN_MAX_LENGTH);
            },
            name: 'restrict-subdomain-max-length-validation',
            message: t('account.create.domainName.error.max.subdomain.length', {
              max: DOMAIN_NAME_SUBDOMAIN_MAX_LENGTH,
            }),
          })
          .test({
            test: (value: string = '') => !value.startsWith('http://') && !value.startsWith('https://'),
            name: 'restrict-domain-name-starts-with-protocol-validation',
            message: t('account.create.domainName.error.starts.with.protocol'),
          })
          .test({
            test: (value: string = '') => {
              const subdomains = value.split('.');

              return !value.startsWith('-') && subdomains.every((subdomain) => !subdomain.startsWith('-'));
            },
            name: 'restrict-start-with-hyphen-validation',
            message: t('account.create.domainName.error.starts.with.hyphen'),
          })
          .test({
            test: (value: string = '') => {
              const subdomains = value.split('.');

              return !value.endsWith('-') && subdomains.every((subdomain) => !subdomain.endsWith('-'));
            },
            name: 'restrict-ends-with-hyphen-validation',
            message: t('account.create.domainName.error.ends.with.hyphen'),
          }),
      ],
      false,
    ),
    managementRegionSchema: Yup.object().test({
      test: (value: Yup.AnyObject) => value?.id,
      message: t('account.create.managementRegion.error'),
    }),
  };
}
