import { Outlet, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { PageLayout } from '@ssnc/ui-layouts';
import { Breadcrumbs, Link, Placeholder, Stack, Text } from '@ssnc/ui-core';
import { InlineNotification } from '@ssnc/ui-pattern-inline-notification';

import { useBreadcrumbs } from '@blueprism/nextgen-shared-hooks';
import { ApiErrorBoundary } from '@blueprism/nextgen-api';

import { StyledTitleText } from 'components';
import { type AccountDetailsParams } from 'types';
import { AppRoutes } from 'routes';
import { TenantStatus } from 'types/entities/Tenant';

import { useTenantDetailsApi } from '../hooks';

import { AccountNavigationRouterTabs } from './components/AccountNavigationRouterTabs';

export function AccountPageLayout() {
  const { formatMessage } = useIntl();
  const { accountId: id = '' } = useParams<AccountDetailsParams>();
  const { tenant, isLoading } = useTenantDetailsApi(id);

  const breadCrumbLinks = [
    { href: AppRoutes.Account, title: formatMessage({ id: 'navigation.accountList.label' }) },
    { title: tenant.name, dependOnLoading: true },
  ];

  const { breadcrumbs } = useBreadcrumbs(breadCrumbLinks, isLoading);

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs ariaLabel={formatMessage({ id: 'navigation.breadcrumbs.accountPage.ariaLabel' })}>
          {breadcrumbs.map(({ title, navigable, ...linkProps }) =>
            navigable ? (
              <Link {...linkProps} key={title}>
                {title}
              </Link>
            ) : (
              <Text key={title}>{title}</Text>
            ),
          )}
        </Breadcrumbs>
      }
      pageTitle={
        isLoading ? (
          <Placeholder width="16rem" height={300} />
        ) : (
          <StyledTitleText type="h3">{tenant.name}</StyledTitleText>
        )
      }
      pageContent={
        <ApiErrorBoundary>
          <Stack gap={300}>
            {tenant.status === TenantStatus.PERMANENTLY_DELETED && (
              <InlineNotification
                isVisible
                variant="danger"
                title={formatMessage({ id: 'account.details.deletedTenant.inlineNotification.title' })}
                notificationMessage={formatMessage({ id: 'account.details.deletedTenant.inlineNotification.message' })}
              />
            )}
            <AccountNavigationRouterTabs />
            <Outlet />
          </Stack>
        </ApiErrorBoundary>
      }
    />
  );
}
