import { type PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { type ComboBoxFieldOption } from '@blueprism/nextgen-platform-components';
import { deepEqual, isEmpty } from '@blueprism/nextgen-utils';

import { getManagementRegionPrefix } from 'services/region/utils/';
import { type ManagementRegion } from 'services/region/hooks/useManagementRegionsApi/api/';

import { useManagementRegionsApi } from './hooks';
import { type RegionContextState, type ManagementRegionState } from './types';

const defaultRegionState = {
  id: '',
  name: '',
  displayName: '',
};

const defaultRegionComboboxState = {
  id: '',
  value: '',
};

const initialState = {
  managementRegionsOptions: [],
  managementRegionsLoading: false,
  selectedManagementRegion: defaultRegionState,
  selectedManagementRegionCombobox: defaultRegionComboboxState,
  selectManagementRegion: () => undefined,
  getManagementRegion: () => undefined,
  managementRegionPrefix: '',
};

export const RegionContext = createContext<RegionContextState>(initialState);

export function RegionContextProvider({ children }: PropsWithChildren) {
  const [managementRegionState, setManagementRegionState] = useState<ManagementRegionState>({
    managementRegionPrefix: '',
    selectedManagementRegion: undefined,
    selectedManagementRegionCombobox: undefined,
  });

  const { managementRegions, isLoading } = useManagementRegionsApi(false);

  const location = useLocation();

  function getManagementRegion(id: string | undefined) {
    const managementRegion = managementRegions?.find((region) => region.id === id);

    return managementRegion;
  }

  function normalizeManagementRegion(region: ManagementRegion) {
    const { id, name, displayName } = region;

    const nextManagementRegion = {
      managementRegionPrefix: getManagementRegionPrefix(name),
      selectedManagementRegion: region,
      selectedManagementRegionCombobox: { id, value: displayName },
    };

    setManagementRegionState((currentManagementRegion) => {
      if (deepEqual(currentManagementRegion, nextManagementRegion)) {
        return currentManagementRegion;
      }

      return nextManagementRegion;
    });
  }

  function selectManagementRegion(region: ComboBoxFieldOption | undefined) {
    const managementRegion = getManagementRegion(region?.id);

    if (isEmpty(managementRegion)) {
      return;
    }

    normalizeManagementRegion(managementRegion);
  }

  const managementRegionsOptions: ComboBoxFieldOption[] = useMemo(() => {
    if (isEmpty(managementRegions)) return [];

    return managementRegions.map(({ id, displayName }) => ({
      id: id,
      value: displayName,
    }));
  }, [managementRegions]);

  useEffect(() => {
    // `regionName` is hard-coded in the URL path in the third place
    // Example: https://domain-name.com/account/page-name/regionName
    const [, , , regionName] = location.pathname.split('/');

    const regionFromURL = managementRegions?.find(({ name }) => name.toLowerCase() === regionName);

    if (!isEmpty(regionFromURL)) {
      normalizeManagementRegion(regionFromURL);

      return;
    }

    if (!isEmpty(managementRegionState.selectedManagementRegionCombobox) || isEmpty(managementRegions)) {
      return;
    }

    const [firstRegion] = managementRegions;

    normalizeManagementRegion(firstRegion);
  }, [location.pathname, managementRegionsOptions, managementRegionState.selectedManagementRegionCombobox]);

  const managementRegionsLoading = isLoading || !managementRegionState.selectedManagementRegionCombobox;

  return (
    <RegionContext.Provider
      value={{
        ...managementRegionState,
        managementRegionsOptions,
        managementRegionsLoading,
        selectManagementRegion,
        getManagementRegion,
      }}
    >
      {children}
    </RegionContext.Provider>
  );
}
