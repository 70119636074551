import { useState } from 'react';

import { useIntl } from 'react-intl';

import { Stack } from '@ssnc/ui-core';
import { FormButtons, FormContent, TextFormField } from '@ssnc/ui-pattern-forms';

import { CancelButton, ComboBoxField, Form, SubmitButton, FormLayout } from '@blueprism/nextgen-platform-components';

import { useRegionContext } from 'services/region';

import { createValidation } from './validationSchema';
import { useAccountCreationPageApi, useRunningDomainApi } from './hooks';
import { initialValues } from './constants';
import { CompanyAndDomainFields } from './components/CompanyAndDomainFields';

export function AccountCreationForm() {
  const { formatMessage } = useIntl();

  const [isDomainVerified, setIsDomainVerified] = useState(false);

  const [isDomainNotVerified, setIsDomainNotVerified] = useState(false);

  const { handleSubmit, handleRedirectToGrid } = useAccountCreationPageApi({
    isDomainVerified,
    setIsDomainNotVerified,
  });
  const { managementRegionsOptions, selectedManagementRegionCombobox, managementRegionsLoading } = useRegionContext();
  const { domain, maxTenantSubdomainLevel, isLoading } = useRunningDomainApi();

  const { adminFirstNameSchema, adminLastNameSchema, emailSchema, managementRegionSchema } = createValidation(
    formatMessage,
    maxTenantSubdomainLevel,
  );

  const initialFormValues = {
    ...initialValues,
    managementRegion: selectedManagementRegionCombobox,
  };

  return (
    <Stack gap={400}>
      <Form initialValues={initialFormValues} onSubmit={handleSubmit} loading={isLoading || managementRegionsLoading}>
        <FormLayout>
          <FormContent>
            <TextFormField
              name="adminFirstName"
              label={formatMessage({ id: 'account.create.firstName.label' })}
              required
              validate={adminFirstNameSchema}
              requiredLabel={formatMessage({ id: 'account.form.requiredLabel' })}
            />
            <TextFormField
              name="adminLastName"
              label={formatMessage({ id: 'account.create.lastName.label' })}
              required
              validate={adminLastNameSchema}
              requiredLabel={formatMessage({ id: 'account.form.requiredLabel' })}
            />
            <TextFormField
              name="email"
              label={formatMessage({ id: 'account.create.email.label' })}
              required
              validate={emailSchema}
              requiredLabel={formatMessage({ id: 'account.form.requiredLabel' })}
            />

            <CompanyAndDomainFields
              domain={domain}
              maxTenantSubdomainLevel={maxTenantSubdomainLevel}
              setIsDomainVerified={setIsDomainVerified}
              isDomainNotVerified={isDomainNotVerified}
              setIsDomainNotVerified={setIsDomainNotVerified}
            />

            <ComboBoxField
              name="managementRegion"
              label={formatMessage({ id: 'account.create.managementRegion.label' })}
              initialValue={selectedManagementRegionCombobox?.value}
              required
              requiredLabel={formatMessage({ id: 'account.form.requiredLabel' })}
              options={managementRegionsOptions}
              validate={managementRegionSchema}
            />
          </FormContent>

          <FormButtons>
            <CancelButton onCancel={handleRedirectToGrid} label={formatMessage({ id: 'buttons.cancel' })} />
            <SubmitButton label={formatMessage({ id: 'buttons.add' })} />
          </FormButtons>
        </FormLayout>
      </Form>
    </Stack>
  );
}
