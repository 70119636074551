import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { FormContent, TextFormField } from '@ssnc/ui-pattern-forms';

import { Form, FormLayout } from '@blueprism/nextgen-platform-components';

import { type AccountDetailsParams } from 'types';
import { useTenantDetailsApi } from 'pages/Account/hooks';

import { type AccountAdminDetailsFormData } from './types';

export function AccountAdminDetailsForm() {
  const { formatMessage } = useIntl();
  const { accountId: id = '' } = useParams<AccountDetailsParams>();

  const { tenant, isLoading } = useTenantDetailsApi(id);

  const initialValues: AccountAdminDetailsFormData = {
    adminFirstName: tenant.adminFirstName,
    adminLastName: tenant.adminLastName,
    masterClientEmail: tenant.masterClientEmail,
  };

  return (
    <Form enableReinitialize initialValues={initialValues} loading={isLoading} viewMode>
      <FormLayout>
        <FormContent>
          <TextFormField
            disabled
            name="adminFirstName"
            label={formatMessage({ id: 'account.details.firstName.label' })}
          />
          <TextFormField
            disabled
            name="adminLastName"
            label={formatMessage({ id: 'account.details.lastName.label' })}
          />
          <TextFormField
            disabled
            name="masterClientEmail"
            label={formatMessage({ id: 'account.details.email.label' })}
          />
        </FormContent>
      </FormLayout>
    </Form>
  );
}
