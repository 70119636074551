import { gql } from 'graphql-request';

export const GET_MANAGEMENT_REGIONS = gql`
  query ManagementRegions {
    managementRegions {
      id
      name
      displayName
    }
  }
`;
