import * as Yup from 'yup';

import { type FormatMessage } from '@blueprism/nextgen-utils';

import { type LanguageKeys } from 'types/language';

export function createMeterValidationSchema(formatMessage: FormatMessage) {
  const t = (text: LanguageKeys) => formatMessage({ id: text });

  return Yup.string()
    .test('no-starts-with-zero', t('account.products.drawer.form.value.error'), (value) => !value?.startsWith('0'))
    .matches(/^[0-9]+$/, t('account.products.drawer.form.value.error'))
    .required(t('account.products.drawer.form.value.required.error'));
}
